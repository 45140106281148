import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {CardActionArea, IconButton, Tooltip} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {getISOWeek, getYear, subWeeks} from 'date-fns';
import React from "react";

var xlsx = require('xlsx')
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DisabledButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }: any) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick
  };
  return (
    <Tooltip title={tooltipText}>
      <Button {...other} {...adjustedButtonProps} />
    </Tooltip>
  );
};

export function FileInput({updateInputs, inputUser}: any) {

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        const filename = file.name
        const reader = new FileReader();

        reader.onload = async (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = await xlsx.read(data, {type: 'array'});
            updateInputs({
                ...inputUser,
                planning: JSON.stringify(workbook),
                filename: filename
            })
        }
        reader.readAsArrayBuffer(file)
    };

    const disabledPlanning = () => {
        if (inputUser.weekNumber) {
            const lastWeekDate = subWeeks(new Date(), 1);
            const lastWeek = getISOWeek(lastWeekDate);
            // Pad a number with a leading zero if it's smaller than 10
            const padWeek = lastWeek.toString().padStart(2, '0');

            const year = getYear(lastWeekDate);

            const weekString = `${year} - ${padWeek}`;
            return inputUser.weekNumber !== weekString;

        } else {
            return true
        }
    }

    const BPTooltip = () => {
        if (disabledPlanning()) {
            return "It is not possible to create a budget prognosis of the weeks before last week, since these numbers " +
                "are already final."
        } else {
            return "Upload your Budget & Planning tool to create the budget prognosis pages."
        }
    }

    return (
        <Box
            width={"33%"}
            marginRight={"10px"}
            flexDirection={"row"}
            display={"flex"}
        >
            {(inputUser.planning && Object.keys(inputUser.planning).length > 0) ? (
                <CardActionArea className={"FileNameButton"} sx={{display: "flex"}}>
                    <p style={{
                        alignSelf: 'left',
                        flex: 1,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                    }}>{inputUser.filename}</p>
                    <IconButton sx={{width: '28px', height: '28px'}} onClick={() => {
                        updateInputs({
                            ...inputUser,
                            planning: "",
                            filename: ""
                        })
                    }}>
                        <ClearIcon fontSize={"small"}/>
                    </IconButton>
                </CardActionArea>
            ) : (
                <Tooltip title={BPTooltip()} placement={'top'} arrow>
                    <div style={{width: "100%"}}>
                    <Button className={"FileButton"} component={"label"} endIcon={<FileUploadIcon style={{
                            padding: "9px"
                    }}/>}
                            disabled={disabledPlanning()}
                    >
                        <p style={{
                            alignSelf: 'left',
                            flex: 1,
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}>Upload B&P Excel</p>
                        <VisuallyHiddenInput
                            type="file"
                            accept={".xlsm"}
                            onChange={(e: any) => {
                                handleFileChange(e)
                            }}
                        />
                    </Button>
                        </div>
                </Tooltip>
            )}
        </Box>
    )
}