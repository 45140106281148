// Styling for the CardButton of the phases,
// that adds a border based on if the phase is selected or not.
import {styled} from "@mui/material/styles";
import {CardActionArea, Divider} from "@mui/material";
import Box from "@mui/material/Box";
import IconTick from "../../assets/Icon_Tick.svg";

const PhaseCardButton = styled(CardActionArea)<any>(({selected}) => ({
    border: selected ? "1px solid " : "1px solid",
    borderColor: selected ? "var(--twd_aqua)" : "lightgrey",
}));

export function PhasesInput({
                                updateInputs,
                                inputUser,
                                phases,
                                detailedPhases,
                                setHasDetailedPhases,
                                updatePhaseList
                            }: any) {

    const detailedPhasesClick = (allPhases: any) => {
        if (detailedPhases) {
            updateInputs({
                ...inputUser,
                phases: [],
                planning: inputUser.planning,
                filename: inputUser.filename
            })
        } else {

            allPhases.map((phase: any, index: any) => {
                updatePhaseList(phase)
            })
        }

        setHasDetailedPhases(!detailedPhases)
    }

    return (
        <Box
            width={"33%"}
        >
            <PhaseCardButton
                sx={{
                    width: "100%",
                    fontSize: "medium",
                    height: "50px",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'left'
                }}
                className={"phaseButton"}
                disabled={!inputUser.weekNumber || !inputUser.projectNumber}
                selected={detailedPhases}
                onClick={() =>
                    detailedPhasesClick(phases)
                }>
                <p style={{
                    alignSelf: 'left',
                    flex: 1,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                }}>Details Per Phase</p>
                {
                    !detailedPhases ? "" : (<img src={IconTick} width={'24px'}/>)
                }
            </PhaseCardButton>
        </Box>
    )
}

export function DetailedPhasesInput({inputUser, phases, hasDetailedPhases, updatePhaseList}: any) {
    return (
        <Box height={"100%"} width={"100%"}>
            {!hasDetailedPhases ? "" : (
                <Divider
                    variant="middle"
                    sx={{
                        width: "60%",
                        marginLeft: "20%",
                        marginRight: "20%",
                        color: "#707070;",
                        borderBottomWidth: 2,
                        marginBottom: "2%",
                    }}
                />
            )}
            <Box height="100%" width={"100%"} sx={{overflowY: "auto"}}>
                {!hasDetailedPhases ? "" : (
                    phases.sort().map((phase: any, index: any) => (
                        <PhaseCardButton
                            sx={{
                                width: "100%",
                                fontSize: "medium",
                                height: "50px",
                                display: "flex",
                            }}
                            className={"phaseButton"}
                            selected={inputUser.phases.includes(phase)}
                            onClick={() => updatePhaseList(phase)}
                        >
                            <p style={{
                                alignSelf: 'left',
                                flex: 1,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}>{phase}</p>
                            {
                                !inputUser.phases.includes(phase) ? "" : (<img src={IconTick} width={'24px'}/>)
                            }

                        </PhaseCardButton>
                    ))

                )}
            </Box>
        </Box>
    )
}