const apiUrl: string | undefined = process.env.REACT_APP_API;
const apiSecret: string | undefined = process.env.REACT_APP_API_SECRET;

export async function getFromFlask(api: any) {
    return await fetch(apiUrl + api, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiSecret as string,
        },
        method: 'GET'
    });
}


export async function postFromFlask(api: any, body: any) {
    return await fetch(apiUrl + api, {
        headers: {
            "Content-Type": 'application/json',
            'x-api-key': apiSecret as string,
        },
        method: 'POST',
        body: JSON.stringify(body)
    });
}