import { Client } from "@microsoft/microsoft-graph-client";

export async function GetProfilePicture(accessToken: string) {
    const graphClient = Client.init({
        authProvider: async (done) => {
            const token = accessToken;
            // Get the token from the auth service
            if (token) {
                done(null, token);
            } else {
                done("Could not get an access token", null);
            }
        },
    });

    try {
        const response = await graphClient.api("/me/photo/$value").get();
        return response;
    } catch (error) {
        return "";
    }
}
