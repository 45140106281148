import type { IPublicClientApplication } from "@azure/msal-browser";
import { useContext } from "react";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { AccountProperties, AccountPropertiesContext } from "./SignIn";
import { styled } from "@mui/material/styles";
import "../../styles/SignOut.css";

const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#00ADEF",

    "&:hover": {
        transform: "scale(0.95)",
        backgroundColor: "#00ADEF",
    },
}));

function signOutClickHandler(
    instance: IPublicClientApplication,
    homeAccountId: string
) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
}

export function SignOutButton() {
    const { instance } = useMsal();

    const { homeAccountId } = useContext(
        AccountPropertiesContext
    ) as AccountProperties;

    return (
        <StyledButton onClick={() => signOutClickHandler(instance, homeAccountId)}>
            Sign Out
        </StyledButton>
    );
}
