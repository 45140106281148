import {PDFViewer} from "./pdfRender";
import {Buttons} from "./Buttons";
import {Box} from "@mui/material";
import React, {useState} from "react";
import "../../styles/GeneralColumn.css";
import {postFromFlask} from "../../services/apiCalls";
import {keyboardState} from "@testing-library/user-event/dist/keyboard/types";


export function OutputFields({changeInputs, setChangeInputs, inputUser, setMessageQueue, messageQueue}: any) {
    const [loading, setLoading] = useState(false);
    const [pdf64, setPDF64] = useState(null);

    const updateReport = () => {
        setLoading(true);
        setChangeInputs(false)
        postFromFlask(
            '/budget-report/create',
            {
                "project_number": inputUser.projectNumber,
                "week_number": inputUser.weekNumber,
                "phases": inputUser.phases,
                "planning": inputUser.planning
            }
        ).then(async (response) => {
            const responseJson = await response.json()
            if (response.ok) {
                setPDF64(responseJson.data);
            } else {
                setMessageQueue([...messageQueue, {message: responseJson.detail.message, kind: "error"}]);
                setPDF64(null)
            }
        }).catch((error: any) => {
            console.error('Error updating events:', error);
            setPDF64(null)
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <Box className={"GeneralRightColumn"}>
            <PDFViewer
                pdf64={pdf64}
                loading={loading}
            />
            <Buttons
                pdf64={pdf64}
                changeInputs={changeInputs}
                updateReport={updateReport}
                loading={loading}
                inputUser={inputUser}
                setMessageQueue={setMessageQueue}
                messageQueue={messageQueue}
            />
        </Box>
    )
}