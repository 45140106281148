import './App.css';
import {Box, ThemeProvider} from "@mui/material";
import {NavBar} from './components/NavBar/NavBar';
import {twdTheme} from "./styles/CustomMaterialTheme";
import {styled} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {getFromFlask} from "./services/apiCalls";
import {InputFields} from "./components/LeftColumn/Inputs";
import {OutputFields} from "./components/RightColumn/Outputs";
import {CustomizedSnackbars} from "./components/Errors";


// Styling for the Box containing the whole app.
const AppBox = styled(Box)(({theme}) => ({
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "var(--twd_grey)",
}));

type InputUserType = {
    projectNumber: string,
    weekNumber: string,
    week: any,
    phases: any[],
    planning: string,
    filename: string,
}

function App() {
    const [inputUser, setInputUser] = useState<InputUserType | {}>({})
    const [projects, setProjects] = useState('');
    const [changeInputs, setChangeInputs] = useState(false);
    const [loadingIsFinished, setLoadingIsFinished] = useState<Boolean>(false);
    const [messageQueue, setMessageQueue] = useState([]);
    const updateInputs = (inputs: InputUserType) => {
        setInputUser(inputs);
        if (inputs.projectNumber && inputs.weekNumber) {
            setChangeInputs(true);
        } else {
            setChangeInputs(false)
        }
    };

    useEffect(() => {
        getFromFlask("/budget-report/phases").then(async response => {
            const responseJson = await response.json()
            setProjects(responseJson.data);
            setLoadingIsFinished(true);
        })
    }, [inputUser])

    return (
        <ThemeProvider theme={twdTheme}>
            {loadingIsFinished &&
                <AppBox className={"app"}>
                    <NavBar/>
                    <Box display="flex"
                         height="calc(100vh - 50px)"
                         margin="2%"
                         className="mainContainer">

                        <InputFields
                            updateInputs={updateInputs}
                            inputUser={inputUser}
                            projects={projects}
                            setMessageQueue={setMessageQueue}
                            messageQueue={messageQueue}
                        ></InputFields>
                        <OutputFields
                            setChangeInputs={setChangeInputs}
                            changeInputs={changeInputs}
                            inputUser={inputUser}
                            setMessageQueue={setMessageQueue}
                            messageQueue={messageQueue}
                        />
                    </Box>
                    <CustomizedSnackbars
                        setMessageQueue={setMessageQueue}
                        messageQueue={messageQueue}
                    />
                </AppBox>
            }
        </ThemeProvider>
    );
}

export default App;