import { Box, Button, IconButton, Menu } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import packageJson from "../../../package.json";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import "../../styles/NavBar.css";

const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#00ADEF",

    "&:hover": {
        transform: "scale(0.95)",
        backgroundColor: "#00ADEF",
    },
}));

const teamslink =
    "https://teams.microsoft.com/l/channel/19%3ac8e81246b96e494d861b38366170cdee%40thread.tacv2/TWD%2520office%2520tools%2520support?groupId=2a09f27f-0dce-4f1f-a628-afc93d91795a&tenantId=4fc4e136-4a65-4aec-8d3f-575155b66940";

function Supportbutton() {
    return (
        <StyledButton
            className="supportbutton"
            onClick={() => window.open(teamslink)}
        >
            Contact support
        </StyledButton>
    );
}
export function QuestionButton() {
    // States
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openInfoMenu, setOpenInfoMenu] = useState<boolean>(false);

    const handleInfoMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenInfoMenu(true);
    };

    const handleCloseInfo = () => {
        setAnchorEl(null);
        setOpenInfoMenu(false);
    };

    return (
        <>
            <IconButton
                className="profilebutton"
                aria-label="menu"
                onClick={handleInfoMenu}
                sx={{ height: "50px" }}
            >
                <HelpOutlineOutlinedIcon sx={{ paddingRight: "10%", color: "white" }} />
            </IconButton>
            <Menu
                className="navbarinfomenu"
                anchorEl={anchorEl}
                open={openInfoMenu}
                onClose={handleCloseInfo}
                keepMounted
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Supportbutton />
                <Box
                    sx={{
                        paddingTop: "5%",
                        display: "inline-block",
                        float: "right",
                        fontSize: "12px",
                    }}
                >
                    v.{packageJson.version}
                </Box>
            </Menu>
        </>
    );
}
