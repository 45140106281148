import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./services/AuthConfig";
import { MsalProvider } from "@azure/msal-react";
import SignIn from "./components/SSO/SignIn";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const publicClientApplication = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        <MsalProvider instance={publicClientApplication}>
            <SignIn>
                <App />
            </SignIn>
        </MsalProvider>
    </React.StrictMode>
);
