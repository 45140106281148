import { createTheme } from "@mui/material";

const twdBlue = "#00adef";
const twdDarkBlue = "#00306b";
const twdRed = "#dd1c1a";
const white = "#ffffff";
const black = "#000000";
export const twdTheme = createTheme({
    // default color palette
    palette: {
        primary: {
            main: twdBlue,
        },
        secondary: {
            main: twdDarkBlue,
        },
    },

    components: {
        // change styles for appbar
        MuiAppBar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    backgroundColor: twdBlue,
                    color: white,
                },
            },
        },

        // change styles for buttons
        MuiButton: {
            styleOverrides: {
                // styles for default state
                root: {
                    color: white,
                    backgroundColor: white,
                },
            },
        },
    },
});

export default twdTheme;