import type { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || "",
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri:
            process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
                ? process.env.REACT_APP_REDIRECT_URL_TEST
                : process.env.REACT_APP_REDIRECT_URL,
        postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage",
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: process.env.REACT_APP_GRAPH_ME_ENDPOINT,
};
