import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {Paper} from "@mui/material";
import {useEffect, useState} from "react";
import "../../styles/Inputs.css";
import "../../styles/GeneralColumn.css";
import {FileInput} from "./UploadFile";
import {DetailedPhasesInput, PhasesInput} from "./DetailsPerPhase";
import {getFromFlask} from "../../services/apiCalls";

function ProjectNumberInput({inputUser,
                                updateInputs,
                                projects,
                                updateAllPhases,
                                setHasDetailedPhases,
                                updateWeeks
                            }: any) {
    return (
        <Box
            className={"InputRows"}
            marginTop={"50px"}
        >
            <Autocomplete
                className={"AutoCompleteFields"}
                disablePortal
                disableClearable={true}
                options={projects}
                getOptionLabel={(project: any) => `${project.ProjectNumber} ${project.ProjectName}`}
                onChange={(event, value) => {
                    if (value && value.ProjectNumber) {
                        updateInputs({
                            ...inputUser,
                            projectNumber: value.ProjectNumber,
                            weekNumber: null,
                            week: {},
                            phases: [],
                            planning: "",
                            filename: ""
                        });
                        updateWeeks(value.ProjectNumber)

                        // Close the detailed phases tab when choosing a new project.
                        setHasDetailedPhases(false);
                        updateAllPhases(value.PhaseName);
                    }
                }
                }
                renderInput={(params) => <TextField {...params} label="Project Number"/>}
                PaperComponent={(props) => <Paper sx={{}} {...props} />}
            />
        </Box>
    )
}

function WeekNumberInput({updateInputs, inputUser, weeks, setHasDetailedPhases}: any) {

    const [maxWeek, setMaxWeek] = useState(null);

    useEffect(() => {
        if (weeks.length > 0) {
            const newMaxWeek = weeks[weeks.length - 1];
            setMaxWeek(weeks[weeks.length - 1]);
            updateInputs({
                ...inputUser,
                weekNumber: newMaxWeek.YearWeek,
                week: newMaxWeek,
                phases: [],
            });
        } else {
            setMaxWeek(null);
        }
    }, [weeks]);

    return (
        <Box width={"33%"} marginRight={"10px"}
        >
            <Autocomplete
                className={"AutoCompleteFields"}
                disablePortal
                disableClearable={true}
                options={weeks}
                getOptionLabel={(week: any) => week.YearWeek}
                getOptionDisabled={(week: any) => week.Disabled === true}
                value={maxWeek}
                onChange={(event, value) => {
                    if (value && value.YearWeek) {
                        setMaxWeek(value)
                        updateInputs({
                            ...inputUser,
                            weekNumber: value.YearWeek,
                            week: value,
                            phases: [],
                            planning: "",
                            filename: ""
                        });
                        setHasDetailedPhases(false);
                    }
                }
                }
                renderInput={(params) => <TextField {...params} label="Week Number"/>}
                PaperComponent={(props) => <Paper sx={{}} {...props} />}
            />
        </Box>
    )
}

export function InputFields({updateInputs, inputUser, projects, setMessageQueue, messageQueue}: any) {
    const [hasDetailedPhases, setHasDetailedPhases] = useState(false)
    const [phases, setPhases] = useState(Array);
    const [weeks, setWeeks] = useState(Array);

    const updateAllPhases = (newPhases: Array<string>) => {
        setPhases(newPhases);
    };

    const updateWeeks = (projectNumber: any) => {
        getFromFlask('/budget-report/projects/' + projectNumber).then(async response => {
            const responseJson = await response.json()
            if (response.ok) {
                setWeeks(responseJson.data);
            } else {
                setMessageQueue([...messageQueue, { message: responseJson.detail.message, kind: "error" }]);
                setWeeks([])
            }
        }).catch((err) => {
            setWeeks([])
        });
    };


    const updatePhaseList = (newPhase: any) => {
        let phasesList = inputUser.phases;
        const phaseIndex = phasesList.indexOf(newPhase);

        if (phaseIndex !== -1) {
            phasesList.splice(phaseIndex, 1);
        } else {
            phasesList.push(newPhase);
        }

        updateInputs({
            ...inputUser,
            phases: phasesList,
        })
    };

    return (
        <Box
            sx={{
                backgroundColor: "white",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
            }}
            className={"GeneralLeftColumn"}
        >
            <ProjectNumberInput
                inputUser={inputUser}
                updateInputs={updateInputs}
                projects={projects}
                updateAllPhases={updateAllPhases}
                setHasDetailedPhases={setHasDetailedPhases}
                updateWeeks={updateWeeks}
            />
            <Box
                className={"InputRows"}
            >
                <WeekNumberInput
                    updateInputs={updateInputs}
                    inputUser={inputUser}
                    weeks={weeks}
                    setHasDetailedPhases={setHasDetailedPhases}
                />
                <FileInput
                    updateInputs={updateInputs}
                    inputUser={inputUser}
                />
                <PhasesInput
                    updateInputs={updateInputs}
                    inputUser={inputUser}
                    phases={phases}
                    detailedPhases={hasDetailedPhases}
                    setHasDetailedPhases={setHasDetailedPhases}
                    updatePhaseList={updatePhaseList}
                    weeks={weeks}
                />
            </Box>
            <Box className={"PhaseRows"}>
                <DetailedPhasesInput
                    inputUser={inputUser}
                    phases={phases}
                    hasDetailedPhases={hasDetailedPhases}
                    updatePhaseList={updatePhaseList}
                />
            </Box>
        </Box>
    );
}