import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import CachedIcon from '@mui/icons-material/Cached';

import "../../styles/Buttons.css";
import {Box, Tooltip} from "@mui/material";
import {postFromFlask} from "../../services/apiCalls";
import {useContext, useState} from "react";
import {AccountProperties, AccountPropertiesContext} from "../SSO/SignIn";

export function Buttons({pdf64, changeInputs, updateReport, loading, inputUser, setMessageQueue, messageQueue}: any) {
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [loadingMail, setLoadingMail] = useState<boolean>(false)

    let reloadButtonStyle = "buttonStyle"
    let generateButtonStyle = "generateButtonStyle"
    if (changeInputs) {
        reloadButtonStyle = "buttonChangeStyle"
        generateButtonStyle = "generateButtonChangeStyle"
    }

    // AccountProperties Context from the SignIn.tsx
    const {accessToken} = useContext(
        AccountPropertiesContext
    ) as AccountProperties;

    return (
        <Box className={'buttonBox'}>
            {pdf64 || loading ? (
                <Box>
                    <Tooltip title={'Reload report'} placement={'top'} arrow>
                        <Button className={reloadButtonStyle}
                                component={CachedIcon}
                                disabled={!changeInputs}
                                sx={{
                                    "&.Mui-disabled": {
                                        background: "lightgrey",
                                    }
                                }}
                                onClick={() => updateReport()}
                        ></Button>
                    </Tooltip>
                    <Tooltip title={'Upload to cloud'} placement={'top'} arrow>
                        <Button
                            className={"buttonStyle"}
                            component={CloudUploadIcon}
                            sx={{
                                "&.Mui-disabled": {
                                    background: "lightgrey",
                                }
                            }}
                            onClick={() => {
                                setLoadingUpload(true)
                                postFromFlask("/budget-report/save", {
                                    "project_number": inputUser.projectNumber,
                                    "week_number": inputUser.weekNumber,
                                    "pdf": pdf64
                                }).then(async response => {
                                    setLoadingUpload(false)
                                    const responseJson = await response.json()
                                        if (response.ok) {
                                            setMessageQueue([...messageQueue, {
                                                message: responseJson.message,
                                                kind: "success",
                                                url: responseJson.data.url,
                                                urlName: "Budget reports"
                                            }]);
                                        } else {
                                            setMessageQueue([...messageQueue, {message: responseJson.message.message, kind: "error"}]);
                                        }
                                    }
                                )
                            }}
                            disabled={changeInputs || !pdf64 || loading || loadingUpload}
                        ></Button>
                    </Tooltip>
                    <Tooltip title={'Create draft mail'} placement={'top'} arrow>
                        <Button
                            className={"buttonStyle"}
                            component={SendIcon}
                            disabled={changeInputs || !pdf64 || loading || loadingMail}
                            sx={{
                                "&.Mui-disabled": {
                                    background: "lightgrey",
                                }
                            }}
                            onClick={() => {
                                setLoadingMail(true)
                                postFromFlask("/budget-report/mail", {
                                    "project_number": inputUser.projectNumber,
                                    "pdf64": pdf64,
                                    "access_token": accessToken,
                                    "week_number": inputUser.weekNumber
                                }).then(async response => {
                                    setLoadingMail(false)
                                    const responseJson = await response.json()
                                        if (response.ok) {
                                            setMessageQueue([...messageQueue, {
                                                message: responseJson.message,
                                                kind: "success",
                                                url: responseJson.data.url,
                                                urlName: "Outlook"
                                            }]);
                                        } else {
                                            setMessageQueue([...messageQueue, {message: responseJson.detail.message, kind: "error"}]);
                                        }
                                    }
                                )
                            }}
                        ></Button>
                    </Tooltip>
                </Box>
            ) : (
                <Tooltip title={'Generate budget report'} placement={'top'} arrow>
                    <Button className={generateButtonStyle}
                            disabled={!changeInputs}
                            sx={{
                                "&.Mui-disabled": {
                                    background: "lightgrey",
                                },
                                fontSize: "80px",
                                fontWeight: "bold",
                                width: "300px",
                            }}
                            onClick={() => updateReport()}
                    >GO</Button>
                </Tooltip>
            )
            }
        </Box>
    )
}
