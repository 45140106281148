import React from "react";
import {Alert, Box, Snackbar} from "@mui/material";

export function CustomizedSnackbars({setMessageQueue, messageQueue}: {
    messageQueue: { message: string, kind: string, url: string, urlName: string }[],
    setMessageQueue: any
}) {
    const handleClose = (index: number) => (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        // Update the messageQueue by removing the item at the specified index
        setMessageQueue((prevMessageQueue: { message: string, kind: string }[]) => {
            return prevMessageQueue.filter((_, i) => i !== index);
        });
    };

    return (
        <Box position={'fixed'} top={'100%'} left={"35%"} sx={{transform: 'translate(-50%, 50%)'}}>
            {messageQueue.map((messageItem: { message: string, kind: string, url: string, urlName: string }, index: number) => (
                <Snackbar
                    key={index}
                    open={true}
                    onClose={handleClose(index)}
                    sx={{width: '500px', marginBottom: (index * 60).toString() + 'px'}}
                >
                    <Alert
                        onClose={handleClose(index)}
                        severity={messageItem.kind === 'error' ? 'error' : 'success'}
                        variant="filled"
                        sx={{width: '100%'}}
                    >{messageItem.url ? (
                        <div>{messageItem.message}<a target='_blank' href={messageItem.url}>{messageItem.urlName}</a></div>
                    ) : (
                        messageItem.message
                    )}
                    </Alert>
                </Snackbar>
            ))}
        </Box>
    );
}
