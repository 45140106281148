import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Box, CircularProgress, Typography} from '@mui/material';
import "../../styles/PDFBox.css";
import "../../styles/GeneralColumn.css";
import FakePDF from "../../assets/PDFexport.svg";
import BRTStart from "../../assets/BRT_start.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function PDFViewer({pdf64, loading}: any) {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [screenScale, setScreenScale] = useState<number>(1);

    useEffect(() => {
        const updateScreenScale = () => {
            setScreenScale(window.devicePixelRatio || 1);
        };

        window.addEventListener('resize', updateScreenScale);

        return () => {
            window.removeEventListener('resize', updateScreenScale);
        };
    }, []);

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    };

    const getPageStyle = (numPages: any) => {
        if (numPages == 1) {
            return 'pdfFirstPage'
        } else {
            return 'pdfPage'
        }
    }

    const scale = 1.3 / screenScale;

    return (
        <Box className={"generalRightColumnContent"} width={(scale * 612 + 60).toString() + 'px'} height={'100%'}
             style={{alignItems: "center", justifyContent: "center"}}>
            {loading ? (
                <Box className={"fakePDFImageBox"} sx={{overflowY: "hidden"}}>
                    <CircularProgress size={"200px"} sx={{position: "absolute", top: "40%", left: "40%", zIndex: 1}}/>
                    <img src={FakePDF} className='fakePDFImage' alt=''/>
                </Box>
            ) : pdf64 ? (
                <Document
                    className={"pdfBox"}
                    file={pdf64}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {
                        [...Array(numPages || 0)].map((_, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                className={getPageStyle(numPages)}
                                scale={scale}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))
                    }
                </Document>
            ) : (
                <img src={BRTStart} style={{width: "80%", marginTop: "120px"}} alt=''/>
            )}

        </Box>
    );
}
